
import tableList from "@/components/renderComponents/tableList/tableList.vue";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from "vue";
import { mainServer } from "@/apiServer/installServer";
import { ElMessageBox, ElMessage } from "element-plus";
export default defineComponent({
  name: "ClassList",
  components: {
    tableList,
  },
  setup() {
    const { proxy }: any = getCurrentInstance();
    let data: any = reactive({
      IsCollapse: true,
      tableListFalg: false,
      keyword: "",
      isShowAddApp: false,
      listData: {
        per_page: 10,
        current_page: 1,
        total: 0,
        data: [],
        keyWord: '',
        config: [
          {
            label: "应用名",
            att: "system_app_name",
            width: "200",
            type: 'url',
            router: '/appContent'
          },
          {
            label: "描述",
            att: "system_app_describe",
            width: "/",
            type: 'string'
          },
          {
            label: "上传限制",
            att: "system_app_limit",
            width: "/",
            type: 'string'
          },
          {
            label: "操作",
            att: "operation",
            width: "120",
            type: 'button'
          },
        ],
      },
    });
    const FnGetSettingApi = function () {
      const params = {};
      mainServer.SetConfigApi(params, true).then((res: any) => {
        console.log("FnGetSettingApi: ConfigInfo ====>", res.data.data.res);
      });
    };
    function FnChangeTableListModel(falg: Boolean) {
      data.tableListFalg = falg;
      console.log(
        "FnChangeTableListModel: data.tableListFalg",
        data.tableListFalg
      );
    };
    function FnCurrentChange(e: any) {
      data.listData.current_page = e;
      FnGetapp();
    };
    function FnRowDelect(item: any) {
      console.log("FnRowDelect: item ---->", item);
      ElMessageBox.confirm("您确定删除此条信息么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // FnDelectClassApi(item.system_class_id);
        })
        .catch(() => {});
    }
    function FnRowEdit(item: any) {
      console.log("FnRowEdit: item ---->", item);
    }
    // function FnCallBack(item: any) {
    //   console.log('FnCallBack: item ---->' , item);
    // }
    async function FnGetapp() {
      const res = await mainServer.Getapp(data.listData, false);
      console.log("FnGetapp: res ---->", res);
      if (res.data.stateMsg === "success") {
        data.listData.data = res.data.data.res.data.map((val: any) => {
          val.operation = [
            {
              name: "删除",
              type: "error",
              fn: FnRowDelect,
            },
            {
              name: "编辑",
              type: "primary",
              fn: FnRowEdit,
            },
          ];
          return val;
        });
        data.listData.total = res.data.data.res.total;
      } else {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "error");
      }
    }
    onMounted(() => {
      FnGetapp();
    });
    return {
      ...toRefs(data),
      proxy,
      FnChangeTableListModel,
      FnCurrentChange,
      FnGetapp,
      // FnCallBack,
    };
  },
});
